import NextLink from 'next/link'
import { useContext } from 'react'

import { SanityLinkBlock } from '@data/sanity/queries/types/blocks'
import { StringsContext } from '@lib/strings-context'
import { getLinkPageUrl, PageType } from '@lib/routes'

import Photo from '@components/photo'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'

type LinkBlockProps = Pick<
  SanityLinkBlock,
  'title' | 'description' | 'link' | 'image'
>

const LinkBlock = ({ title, description, link, image }: LinkBlockProps) => {
  const strings = useContext(StringsContext)

  const linkUrl = getLinkPageUrl({
    pageType: link._type as PageType,
    slugs: link.slug?.current,
  })

  return (
    <NextLink href={linkUrl} className="group relative block">
      <Photo image={image} showLink={false} className="relative">
        <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,0,0,0)] to-[rgba(0,0,0,0.5)] opacity-80 transition-opacity duration-300 group-hover:opacity-100" />
      </Photo>

      <div className="absolute bottom-0 inset-x-0 p-5 lg:p-8 space-y-5">
        <div className="space-y-1">
          <h3 className="text-white">{title}</h3>
          {!!description && <p className="text-white">{description}</p>}
        </div>

        <span className="inline-flex items-center gap-2.5 text-sm text-white font-medium">
          <Button
            variant={ButtonVariant.FILLED}
            color={ButtonColor.BLUE}
            size={ButtonSize.SMALL}
            icon="ChevronDown"
            className="-rotate-90 !p-1 pointer-events-none"
          />
          {strings.buttonSeeMore}
        </span>
      </div>
    </NextLink>
  )
}

export default LinkBlock
