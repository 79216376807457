import cx from 'classnames'
import {
  type KeenSliderHooks,
  type KeenSliderInstance,
  type KeenSliderOptions,
  useKeenSlider,
} from 'keen-slider/react'
import { type ReactNode, useState } from 'react'

import { screens } from '@lib/theme'

import Button, { ButtonSize } from '@components/buttons/button'
import { backgroundColorClasses } from '@lib/color'
import { SanityColorValue } from '@data/sanity/queries/types/color'
import { SanityContentCarouselSize } from '@data/sanity/queries/types/blocks'

/**
 * Gets slide count per view from slider options.
 */
export const getSlidesPerView = (
  slider: KeenSliderInstance<unknown, unknown, KeenSliderHooks>
) => {
  const slideOptions = slider.options.slides

  if (
    slideOptions &&
    typeof slideOptions === 'object' &&
    slideOptions.perView &&
    typeof slideOptions.perView === 'number'
  ) {
    return slideOptions.perView
  }
}

interface CarouselProps {
  title?: string
  size?: SanityContentCarouselSize
  items: ReactNode[]
  options?: KeenSliderOptions
  hasArrows?: boolean
  background?: SanityColorValue
  hasContainer?: boolean
  className?: string
}

const calculatePerView = (
  base: number,
  itemsLength: number,
  size?: SanityContentCarouselSize
) => {
  // Add 1 to the base if the size is 'compact' for tighter spacing.
  // Add a fractional adjustment (0.15) if the total items exceed the base,
  const isCompact = size === 'compact';
  const adjustment = isCompact ? 1 : 0;
  return itemsLength > base ? base + 0.15 + adjustment : base + adjustment;
};

const Carousel = ({
  title,
  size,
  items,
  options,
  hasArrows,
  background,
  hasContainer,
  className,
}: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slidesPerView, setSlidesPerView] = useState(1)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: {
      spacing: 24,
      perView: calculatePerView(1, items.length, size),
    },
    breakpoints: {
      [`(min-width: ${screens.sm})`]: {
        slides: {
          spacing: 24,
          perView: calculatePerView(2, items.length, size),
        },
      },
      [`(min-width: ${screens.md})`]: {
        slides: {
          spacing: 24,
          perView: calculatePerView(3, items.length, size),
        },
      },
      [`(min-width: ${screens.lg})`]: {
        slides: {
          spacing: 28,
          perView: calculatePerView(3, items.length, size),
        },
      },
    },
    created(slider) {
      const newSlidesPerView = getSlidesPerView(slider)

      if (newSlidesPerView) {
        setSlidesPerView(newSlidesPerView)
      }
    },
    optionsChanged(slider) {
      const newSlidesPerView = getSlidesPerView(slider)

      if (newSlidesPerView) {
        setSlidesPerView(newSlidesPerView)
      }
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track?.details?.rel)
    },
    ...options,
  })

  const handlePrevClick = () => {
    const prevIndex = Math.max(
      currentSlide > slideCount - slidesPerView
        ? slideCount - slidesPerView - 1
        : currentSlide - 1,
      0
    )
    slider.current?.moveToIdx(prevIndex)
    setCurrentSlide(prevIndex)
  }

  const handleNextClick = () => {
    const nextIndex = Math.min(currentSlide + 1, slideCount - slidesPerView)
    slider.current?.moveToIdx(nextIndex)
    setCurrentSlide(nextIndex)
  }

  const slideCount = slider.current?.track?.details?.slides?.length ?? 0

  return (
    <div className={cx(className, background && backgroundColorClasses[background], 'w-full')}>
      <div
        className={cx(className, 'relative', {
          'container py-10 md:py-16': hasContainer,
        })}
      >
        <div className="flex gap-x-4 justify-between mb-4">
          {title && <h2 className="mb-4">{title}</h2>}

          {hasArrows && slideCount > slidesPerView && (
            <div className="flex items-start ml-auto">
              <Button
                onClick={handlePrevClick}
                disabled={currentSlide === 0}
                icon="ChevronDown"
                className="rotate-90 !p-0.5"
                size={ButtonSize.SMALL}
              />

              <Button
                onClick={handleNextClick}
                disabled={currentSlide >= slideCount - slidesPerView}
                icon="ChevronDown"
                className="-rotate-90 !p-0.5"
                size={ButtonSize.SMALL}
              />
            </div>
          )}
        </div>

        <div
          ref={sliderRef}
          className={cx(
            'flex relative will-change-transform touch-action touch-action-pan-y keen-slider',
            { 'overflow-hidden': !hasContainer }
          )}
        >
          {items.map((item, index) => (
            <div className="keen-slider__slide" key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Carousel
