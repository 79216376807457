import { useContext } from 'react'

import {
  type SanityBlogCategory,
  type SanityBlogPostOptions,
  type SanityBlogPostWithoutBody,
} from '@data/sanity/queries/types/blog'
import { getFormattedDate } from '@lib/format'
import { LanguageContext } from '@lib/language-context'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

interface BlogPostCardProps {
  post: SanityBlogPostWithoutBody
  options?: SanityBlogPostOptions
}

type BlogPostCardAuthorProps = Required<
  Pick<SanityBlogPostWithoutBody, 'author'>
> &
  Pick<SanityBlogPostWithoutBody, 'date'> &
  Pick<BlogPostCardProps, 'options'> & {
    createdAt: SanityBlogPostWithoutBody['_createdAt']
  }

interface BlogPostCardCategoryProps {
  category: SanityBlogCategory
}

const BlogPostCardAuthor = ({
  author,
  date,
  createdAt,
  options,
}: BlogPostCardAuthorProps) => {
  const { slug, name, photo } = author

  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)

  const blogAuthorUrl = getLinkPageUrl({
    pageType: 'blogAuthor',
    slugs: slug?.current,
  })

  return (
    <div className="flex items-center">
      {!!options?.showAuthorPhoto && !!photo && (
        <Photo
          image={photo}
          width={40}
          height={40}
          className="rounded-full overflow-hidden shrink-0 w-[40px] h-[40px] mr-2"
          imageClassName="object-cover"
        />
      )}
      <div>
        {!!options?.showAuthorName && (
          <p className="text-sm">
            {strings.blogPostBy}{' '}
            <SimpleLink href={blogAuthorUrl}>{name}</SimpleLink>
            {options?.showDate
              ? `, ${getFormattedDate(date ?? createdAt, locale)}`
              : ''}
          </p>
        )}
      </div>
    </div>
  )
}

const BlogPostCardCategory = ({ category }: BlogPostCardCategoryProps) => {
  const { slug, name } = category

  const blogCategoryUrl = getLinkPageUrl({
    pageType: 'blogCategory',
    slugs: slug.current,
  })

  return (
    <SimpleLink
      href={blogCategoryUrl}
      className="mr-2 text-xs underline hover:text-orange"
    >
      {name}
    </SimpleLink>
  )
}

const BlogPostCard = ({ post, options }: BlogPostCardProps) => {
  if (!post) {
    return null
  }

  const {
    coverImage,
    slug,
    title,
    excerpt,
    categories,
    author,
    date,
    _createdAt: createdAt,
  } = post

  const blogPostUrl = getLinkPageUrl({
    pageType: 'blogPost',
    slugs: slug.current,
  })

  return (
    <div className="group">
      {!!coverImage && !!options?.showCoverImage && (
        <SimpleLink href={blogPostUrl} className="block overflow-hidden mb-5">
          <Photo
            image={{
              ...coverImage,
              customRatio: 1.7777777778,
              borderRadius: 'lg',
            }}
          />
        </SimpleLink>
      )}

      <SimpleLink href={blogPostUrl} className="block mb-4">
        <h3>{title}</h3>
      </SimpleLink>

      {!!options?.showExcerpt && <p className="mb-6">{excerpt}</p>}

      {!!options?.showCategories && !!categories && (
        <div className="flex flex-wrap mb-4">
          {categories.map((category, index) => (
            <BlogPostCardCategory key={index} category={category} />
          ))}
        </div>
      )}

      {!!author &&
        (!!options?.showAuthorPhoto ||
          !!options?.showAuthorName ||
          !!options?.showDate) && (
          <BlogPostCardAuthor
            author={author}
            date={date}
            createdAt={createdAt}
            options={options}
          />
        )}
    </div>
  )
}

export default BlogPostCard
